import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import ContentCard from '../../components/content-card';
import PrimaryButton from '../../components/buttons/primary-button';
import RadioGroup from '../../components/inputs/radiogroup';
import RadioButton from '../../components/inputs/radiobutton';
import { updateAnswers } from '../../actions/answers';
import SubheaderTypography from '../../components/subheader-typography';
import { getSituation } from '../../selectors/answers';
import styles from './styles';

export const situations = {
    SERVED: 'served',
    JUDGEMENT: 'judgement',
    COLLECTIONS: 'collections',
};

const OutlinedRadioButtons = styled(RadioGroup)(styles);

const DeclareSituationForm = () => {
    const { control, handleSubmit } = useForm({
        defaultValues: {
            situation: situations.SERVED,
        },
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const situation = useSelector(getSituation);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogBody, setDialogBody] = useState('');
    const [showTooltipDialog, setShowTooltipDialog] = useState(false);

    const onSubmit = (values) => {
        dispatch(updateAnswers('judgement', values));
        const next = `/${values.situation}`;
        navigate(next);
    };

    if (situation) {
        return <Navigate replace to={`/${situation}`} />;
    }

    const openHarassmentTooltip = () => {
        setDialogTitle('"I am dealing with a debt collector"');
        setDialogBody('Choose this option to learn more about debt collector harassment, and if a collector might be breaking the law.');
        setShowTooltipDialog(true);
    };

    const openServedTooltip = () => {
        setDialogTitle('"I have been served with court papers"');
        setDialogBody('Choose this option to learn about legal defenses to a lawsuit and how to file a written Answer to the Complaint.');
        setShowTooltipDialog(true);
    };

    const openJudgementTooltip = () => {
        setDialogTitle('"I have a garnishment or money judgment"');
        setDialogBody('Choose this option to learn what income and property a debt collector isn’t allowed to take.');
        setShowTooltipDialog(true);
    };

    return (
        <Container>
            <SubheaderTypography variant="h1" mt={6} mb={3}>
                Welcome!
            </SubheaderTypography>
            <SubheaderTypography mb={3}>
                Use the Montana Debt Options Tool to find out if a debt collector could be breaking the law, if you have any protected income or property, and if you have any defenses to a debt lawsuit.
            </SubheaderTypography>
            <ContentCard>
                <Typography variant="h1" mb={1.25}>
                    Which best describes your situation?
                </Typography>
                <Typography mb={2}>
                    We&apos;ll recommend resources for your situation. You can go through the other options after you complete your first option.
                </Typography>
                <form>
                    <OutlinedRadioButtons
                        name="situation"
                        label="Describe your situation"
                        control={control}
                        hiddenLabel
                    >
                        <RadioButton
                            key={situations.SERVED}
                            value={situations.SERVED}
                            label="I have been served with court papers"
                            description="When a debt collector sues you, you will be served with a Summons and Complaint."
                            onOpenTooltip={openServedTooltip}
                        />
                        <RadioButton
                            key={situations.COLLECTIONS}
                            value={situations.COLLECTIONS}
                            label="I am dealing with a debt collector"
                            description="A debt collector is contacting me about a debt they say I owe. But I have not been sued."
                            onOpenTooltip={openHarassmentTooltip}
                        />
                        <RadioButton
                            key={situations.JUDGEMENT}
                            value={situations.JUDGEMENT}
                            label="I have a garnishment or money judgment"
                            description="A debt collector has garnished my wages, taken my property, or got a court judgment against me."
                            onOpenTooltip={openJudgementTooltip}
                        />
                    </OutlinedRadioButtons>
                    <PrimaryButton onClick={handleSubmit(onSubmit)}>
                        Submit
                    </PrimaryButton>
                </form>
            </ContentCard>
            <Dialog
                open={showTooltipDialog}
                onClose={() => setShowTooltipDialog(false)}
            >
                <DialogTitle sx={{ maxWidth: '90%' }}>
                    {dialogTitle}
                    <IconButton
                        aria-label="close"
                        onClick={() => setShowTooltipDialog(false)}
                        sx={{
                            position: 'absolute',
                            right: 15,
                            top: 15,
                        }}
                    >
                        <CloseIcon sx={{ fontSize: '2.4rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography mt={2} mb={2}>
                        {dialogBody}
                    </Typography>
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default DeclareSituationForm;
