import FlowActions from '../action-types/flow';

const initial = {
    completedFlows: [],
};

// eslint-disable-next-line default-param-last
const FlowReducer = (state = initial, action) => {
    const { payload, type } = action;

    switch (type) {
    case FlowActions.COMPLETE_FLOW: {
        const newFlows = state.completedFlows.filter((flow) => flow !== payload);
        newFlows.unshift(payload);
        return { ...state, completedFlows: newFlows };
    }
    case FlowActions.RESTART_FLOW:
        return { ...state, completedFlows: state.completedFlows.filter((flowName) => flowName !== payload) };
    default:
        return state;
    }
};

export default FlowReducer;
