import React, { Suspense, lazy } from 'react';
import {
    Routes,
    Navigate,
    Route,
} from 'react-router-dom';
import { styled } from '@mui/material/styles';
import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme';
import Footer from '../../components/footer';
import styles from './styles';
import Header from '../../components/header';
import ExemptLayout from '../exempt';
import HarassmentsLayout from '../harassments';

const Error404 = lazy(() => import('../../routes/error/404'));
const Results = lazy(() => import('../../routes/results'));
const ServedOverview = lazy(() => import('../../routes/served-overview'));
const JudgementOverview = lazy(() => import('../../routes/judgement-overview'));
const CollectionsOverview = lazy(() => import('../../routes/collections-overview'));
const GarnishmentCalculator = lazy(() => import('../../routes/garnishment-calculator'));
const PossibleLegalDefenses = lazy(() => import('../../routes/legal-defenses'));
const DebtLawsuitsOverview = lazy(() => import('../../routes/debt-lawsuits-overview'));
const RecentlyServedQuestion = lazy(() => import('../../routes/legal-defenses/served-recently'));
const ServedInLifetimeQuestion = lazy(() => import('../../routes/legal-defenses/served-lifetime'));
const Resources = lazy(() => import('../../routes/resources'));
const CreditorTips = lazy(() => import('../../routes/creditor-tips'));

const SubHeader = styled('div')(styles.subheader);
const PageContent = styled('div')(styles.pageContent);

const PublicLayout = () => (
    <Suspense fallback={<div />}>
        <main>
            <Header />
            <SubHeader />
            <PageContent>
                <Routes>
                    <Route path="/error/404" element={<Error404 />} />
                    <Route path="/results" element={<Results />} />
                    <Route path="/served" element={<ServedOverview />} />
                    <Route path="/judgement" element={<JudgementOverview />} />
                    <Route path="/collections" element={<CollectionsOverview />} />
                    <Route path="/exempt/*" element={<ExemptLayout />} />
                    <Route path="/harassments/*" element={<HarassmentsLayout />} />
                    <Route path="/garnishment" element={<GarnishmentCalculator />} />
                    <Route path="/calculator" element={<GarnishmentCalculator standalone />} />
                    <Route path="/legalDefenses" element={<PossibleLegalDefenses />} />
                    <Route path="/legalDefenses/servedRecently" element={<RecentlyServedQuestion />} />
                    <Route path="/legalDefenses/servedLifetime" element={<ServedInLifetimeQuestion />} />
                    <Route path="/debtLawsuitsOverview" element={<DebtLawsuitsOverview />} />
                    <Route path="/creditorTips" element={<CreditorTips />} />
                    <Route path="/resources" element={<Resources />} />
                    <Route path="/*" element={<Navigate replace to="/error/404" />} />
                </Routes>
                <Footer />
            </PageContent>
        </main>
    </Suspense>
);

export default withRoot(PublicLayout, themer);
