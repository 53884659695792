import AnswersTypes from '../action-types/answers';

export const initial = {
    exempt: {},
    judgement: {},
    garnishment: {},
    legalDefenses: {},
    harassments: {},
};

// eslint-disable-next-line default-param-last
const AnswersReducer = (state = initial, action) => {
    const { payload, type } = action;

    switch (type) {
    case AnswersTypes.UPDATE_ANSWERS:
        return { ...state, [payload.flowName]: { ...state[payload.flowName], ...payload.values } };
    case AnswersTypes.RESET_ANSWERS:
        return { ...state, [payload.flowName]: initial[payload.flowName] };
    default:
        return state;
    }
};

export default AnswersReducer;
