import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import noop from '../../../utils/noop';
import styles from './styles';

const QuaternaryStyledButton = styled(Button)(styles);

const QuaternaryButton = ({
    children,
    onClick,
    icon,
    ...props
}) => (
    <QuaternaryStyledButton
        variant="contained"
        onClick={onClick}
        {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
        <Box>
            { children }
        </Box>
        {icon && icon}
    </QuaternaryStyledButton>
);

QuaternaryButton.defaultProps = {
    children: null,
    onClick: noop,
    icon: null,
};

QuaternaryButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    icon: PropTypes.node,
};

export default QuaternaryButton;
