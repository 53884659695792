import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Footer from '../../components/footer';
import Header from '../../components/header';
import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme';

const PrivacyPolicy = () => (
    <main>
        <Header />
        <Container>
            <Typography textAlign="center" variant="h1" mb={3} mt={3}>
                Privacy Policy / About Us
            </Typography>
            <Typography variant="body1" mb={2}>
                <a target="_blank" rel="noreferrer" href="https://www.mtlsa.org/">Montana Legal Services Association (MLSA)</a> is a private, non-profit law firm that provides free legal help to eligible clients throughout Montana. As well as serving individual clients, MLSA provides free legal tools, like this website, for use by the public. Using this website does not create an attorney-client relationship between you and MLSA. We created this website to help consumers better understand their legal rights and take helpful action on their consumer debt matters. This project was funded through a Legal Services Corporation Technology Initiative Grant.
            </Typography>
            <Typography variant="body1" mb={2}>
                MLSA understands that your privacy is important to you. This Privacy Policy is intended to help you understand what information about you is collected while using this website and how that information is used.
            </Typography>
            <Typography variant="body1" mb={2}>
                The interactive tools on this website use information you provide through checklists and surveys to direct you to relevant legal information and resources. We do not gather any information that could personally identify who you are, nor do we store any user data in a database or back-end server. The information you provide by using the interactive tools are cached temporarily in your browser and are anonymous. All cached information is deleted within 24 hours. You can also manually clear your cache in your browser’s settings. If you do not want to use our interactive tools, you can find free legal articles and forms at <a target="_blank" rel="noreferrer" href="https://www.montanalawhelp.org/">MontanaLawHelp.org</a>.
            </Typography>
            <Typography variant="h2" mb={2} pt={1}>
                Google Analytics
            </Typography>
            <Typography variant="body1" mb={2}>
                We use Google Analytics to track visits to our site. Google Analytics uses cookies on your web browser to collect and track this data. Data is collected and presented in a way that makes it so individual users cannot be identified. We can only see trends and statistics, not information about individual, specific users. Google Analytics tracks data such as:
            </Typography>
            <Typography variant="body1" mb={2}>
                <ul>
                    <li>
                        Number of sessions on the website
                    </li>
                    <li>
                        Geographic location of users – through IP address tracking
                    </li>
                    <li>
                        Behavior on the website, such as:
                        <ul>
                            <li>
                                Time spent on pages
                            </li>
                            <li>
                                Pages visited
                            </li>
                            <li>
                                Links clicked
                            </li>
                            <li>
                                How you found this website (Internet search, social media, direct link)
                            </li>
                        </ul>
                    </li>
                    <li>
                        Type of device (desktop, mobile phone, tablet) used
                    </li>
                </ul>
            </Typography>
            <Typography variant="body1" mb={2}>
                How we use Google Analytics data:
            </Typography>
            <Typography variant="body1" mb={2}>
                <ul>
                    <li>
                        To track how much our content is used and visited. This helps us prioritize which content to update and promote.
                    </li>
                    <li>
                        To report to our funders and board of directors about website usage.
                    </li>
                    <li>
                        To inform how we design and present our website – so we can make it work better for more users.
                    </li>
                    <li>
                        To identify problems on our website.
                    </li>
                </ul>
            </Typography>
            <Typography variant="body1" mb={2}>
                We share Google Analytics data with:
            </Typography>
            <Typography variant="body1" mb={2}>
                <ul>
                    <li>
                        Our funders and potential funders.
                    </li>
                    <li>
                        Partners in the legal aid and legal aid technology communities – for joint work on website improvement projects or for informational purposes/studies of legal aid website use.
                    </li>
                    <li>
                        Contractors performing development or maintenance work on our website.
                    </li>
                </ul>
            </Typography>
            <Typography variant="body1" mb={2}>
                We <strong>do not</strong> sell data, or use data to try to sell products or services to users. We may use data to target advertising of our services or website as a way of conducting outreach for the free legal services and information we provide.
            </Typography>
            <Typography variant="body1" mb={2}>
                You can opt-out of allowing Google Analytics to collect your data by using the <a target="_blank" rel="noreferrer" href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser Add-on</a>.  Learn more about <a target="_blank" rel="noreferrer" href="https://support.google.com/analytics/answer/6004245">Google Analytics Data Collection</a>.
            </Typography>
            <Typography variant="h2" mb={2} pt={1}>
                Website Feedback Form
            </Typography>
            <Typography variant="body1" mb={2}>
                Our website feedback form is an optional way for users to leave comments, feedback, and suggestions on our website.
            </Typography>
            <Typography variant="h2" mb={2} pt={1}>
                How we use Feedback Form data
            </Typography>
            <Typography variant="body1" mb={2}>
                We use comments and feedback submitted by users to improve our website and content. If a user chooses to leave their email, we may contact them using their email if their comment or suggestion requires a response. We <strong>do not</strong> use or share these email addresses for any other purpose.
            </Typography>
            <Typography variant="body1" mb={2}>
                We may share your feedback with funders and potential funders but we will not share your email address or any other information that could personally identify you. The reason we would share this information is to describe how people use our tools to organizations interested in supporting our work. In addition to funders, our website developer may have access to these feedback records, but they are not exported or shared.
            </Typography>
            <Typography variant="body1" mb={2}>
                If you have submitted a comment with your email and would like to have it deleted from our website, contact our site administrator at contactus@mtlsa.org with:
            </Typography>
            <Typography variant="body1" mb={2}>
                <ul>
                    <li>
                        the approximate date you submitted your comment, and
                    </li>
                    <li>
                        the email address you used, and would like to have deleted from the site.
                    </li>
                </ul>
            </Typography>
            <Typography variant="h2" mb={2} pt={1}>
                Can I donate to support your work?  How do you process donations?
            </Typography>
            <Typography variant="body1" mb={2}>
                This tool is offered free of charge, however, we do accept donations so that we can continue to offer our services for free.  We accept donations via credit card through a secure third-party site, <a target="_blank" rel="noreferrer" href="https://neonone.com/privacypolicy/">Neon One</a>. We greatly appreciate any and all support for our work. If you wish to make a donation, you can visit our <a target="_blank" rel="noreferrer" href="https://mtlsa.app.neoncrm.com/forms/donate-now">donor page</a>.
            </Typography>
            <Typography variant="h2" mb={2} pt={1}>
                We may update this policy
            </Typography>
            <Typography variant="body1" mb={2}>
                We will update this policy as needed. We will post and highlight any changes on this page, and we will post the date of the latest update at the top of the page. Please check back regularly for the most up-to-date information.
            </Typography>
            <Typography variant="h2" mb={2} pt={1}>
                Questions? Concerns? Contact Us.
            </Typography>
            <Typography variant="body1" mb={10}>
                If you have any questions or concerns about this Privacy Policy or our data collection and usage, please contact us at <a href="mailto:contactus@mtlsa.org">contactus@mtlsa.org</a>.
            </Typography>
        </Container>
        <Footer />
    </main>
);

export default withRoot(PrivacyPolicy, themer);
