import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import styles from './styles';

const ContentCard = ({ children, containerProps, ...props }) => (
    <Paper sx={styles} {...props}>
        <Container {...containerProps}>
            {children}
        </Container>
    </Paper>
);

ContentCard.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    containerProps: PropTypes.object,
};

ContentCard.defaultProps = {
    children: null,
    containerProps: {},
};

export default ContentCard;
