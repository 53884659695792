import { createSelector } from 'reselect';

/* eslint-disable import/prefer-default-export */
export const getAllAnswers = (state) => state.answers;

export const getAnswersForFlow = createSelector(
    getAllAnswers,
    (answers) => (flowName) => answers[flowName],
);

export const getHasResults = createSelector(
    getAllAnswers,
    (answers) => (
        Object.entries(answers.legalDefenses).length > 0
            || Object.entries(answers.exempt).length > 0
            || Object.entries(answers.garnishment).length > 0
            || Object.entries(answers.harassments).length > 0
    ),
);

export const getSituation = createSelector(
    getAllAnswers,
    (answers) => answers.judgement.situation,
);

// garnishment selectors
export const getSkippedGarnishment = createSelector(
    getAllAnswers,
    (answers) => answers.garnishment.skippedCalculator,
);

export const getSkippedFlow = createSelector(
    getAllAnswers,
    (answers) => (flowName) => (
        answers[flowName].skipped
    ),
);

export const getStartedFlow = createSelector(
    getAllAnswers,
    (answers) => (flowName) => (answers[flowName].started),
);
