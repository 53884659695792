import React, { lazy } from 'react';
import {
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';

const HarassmentsGettingStarted = lazy(() => import('../../routes/harassments'));
const CollectionsQuestion = lazy(() => import('../../routes/harassments/collections'));
const ServedQuestion = lazy(() => import('../../routes/harassments/served'));
const CollectorActivityForm = lazy(() => import('../../routes/harassments/collector-activity'));

const HarassmentsLayout = () => (
    <Routes>
        <Route path="/" element={<Navigate replace to="start" />} />
        <Route path="/start" element={<HarassmentsGettingStarted />} />
        <Route path="/collections" element={<CollectionsQuestion />} />
        <Route path="/served" element={<ServedQuestion />} />
        <Route path="/collectorActivity" element={<CollectorActivityForm />} />
    </Routes>
);

export default HarassmentsLayout;
