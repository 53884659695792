import { combineReducers } from 'redux';
import Auth from './auth';
import User from './user';
import Answers from './answers';
import Results from './results';
import Flow from './flow';

const Reducers = () => combineReducers({
    auth: Auth,
    user: User,
    answers: Answers,
    results: Results,
    flow: Flow,
});

export default Reducers;
