export default ({ theme }) => ({
    color: theme.palette.quaternary.contrastText,
    border: `1px solid ${theme.palette.quaternary.contrastText}`,
    backgroundColor: theme.palette.quaternary.main,
    fontWeight: 500,
    textTransform: 'none',
    justifyContent: 'space-between',

    '&:hover': {
        backgroundColor: theme.palette.quaternary.dark,
    },

    '&:focus': {
        backgroundColor: theme.palette.quaternary.dark,
    },

    '&:active, &[data-clicked=true]': {
        backgroundColor: theme.palette.quaternary.dark,
    },

    disabled: {
        color: theme.palette.quaternary.contrastText,
        backgroundColor: theme.palette.quaternary.main,
    },
});
