export default {
    subheader: ({ theme }) => ({
        height: '315px',
        backgroundColor: theme.palette.primary.light,
        [theme.breakpoints.up('sm')]: {
            height: '300px',
        },
    }),
    pageContent: ({ theme }) => ({
        marginTop: '-315px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '-300px',
        },
    }),
};
