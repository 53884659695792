import { flowNames } from './flows';

export default {
    exempt: {
        home: ['viewProtectedProperty', 'protectedRequest'],
        car: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        personal: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        workEquipment: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        medical: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        ira: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        hsa: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        lifeInsurance: ['viewUnprotectedProperty', 'protectedRequest', 'contactLawyer'],
        burial: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        childSupport: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        alimony: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        disability: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        retirement: ['contactLawyer', 'protectedRequest', 'viewUnprotectedProperty'],
        socialSecurity: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        ssi: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        unemployment: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        veterans: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        tanf: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        workersComp: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        wages: ['contactLawyer', 'protectedRequest', 'viewUnprotectedProperty'],
        tribal: ['contactLawyer', 'protectedRequest', 'viewUnprotectedProperty'],
        tribalPayments: ['contactLawyer', 'protectedRequest', 'viewUnprotectedProperty'],
        rv: ['viewProtectedProperty', 'protectedRequest', 'contactLawyer'],
        bankAccounts: ['contactLawyer', 'protectedRequest', 'viewUnprotectedProperty'],
    },
    legalDefenses: {
        hasViewed: ['debtForms'],
        wasRecentlyServed: [flowNames.LEGAL_DEFENSES, 'debtForms', 'contactLawyerLawsuits'],
        hasBeenServed: [flowNames.LEGAL_DEFENSES, 'debtForms', 'contactLawyerLawsuits'],
        hasNeverBeenServed: [flowNames.LEGAL_DEFENSES, 'debtForms', 'contactLawyerLawsuits'],
    },
    harassments: {
        isInCollections: ['contactLawyerHarassments'],
        isNotInCollections: ['consumerProtectionAct'],
        contactedWork: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        contactedFamily: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        contactedEx: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        jointDebt: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        sharedInformation: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        noInformation: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        putDebtOnCreditReport: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        contactedMe: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        frequentContact: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        harassment: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        noIdentity: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        jail: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        profanity: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        threats: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        misleading: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        falseReports: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        recurringContactWithoutConsent: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        sueForOldDebt: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
        unrecognizedDebt: ['debtHarassmentInformation', 'debtHarassmentLegalForms', 'contactLawyerHarassments'],
    },
};
