import React from 'react';
import { Link } from 'react-router-dom';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DownloadIcon from '@mui/icons-material/Download';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import QuaternaryButton from '../components/buttons/quaternary-button';

export default {
    legalDefenses: {
        title: 'Going to Court',
        icon: <OpenInNewIcon />,
        actions: [
            <QuaternaryButton
                href="/legalDefenses"
                icon={<OpenInNewIcon />}
                component={MuiLink}
            >
                Filing a Written Answer (article)
            </QuaternaryButton>,
        ],
        order: 0,
    },
    debtForms: {
        title: 'Court forms for debt lawsuits',
        icon: <InsertDriveFileIcon />,
        actions: [
            <QuaternaryButton
                target="_blank"
                href="https://lawhelpinteractive.org/Interview/GenerateInterview/8155/engine"
                icon={<OpenInNewIcon />}
                component={MuiLink}
            >
                Go to the Answer to Debt Complaint (interactive form)
            </QuaternaryButton>,
            <Typography variant="body2">
                Leave this website to get started on your written Answer to a Debt Complaint.
            </Typography>,
            <QuaternaryButton
                target="_blank"
                download="Pro_Se_Consumer_Answer_Form_Rev._22-05-27.pdf"
                href={`${process.env.PUBLIC_URL}/files/Pro_Se_Consumer_Answer_Form_Rev._22-05-27.pdf`}
                icon={<DownloadIcon />}
                component={MuiLink}
            >
                Download the Answer to Debt Complaint (PDF)
            </QuaternaryButton>,
            <Typography variant="body2">
                Download the write-in-the-blank Answer to complete by hand.
            </Typography>,
            <QuaternaryButton
                target="_blank"
                href="https://lawhelpinteractive.org/Interview/GenerateInterview/7100/engine"
                icon={<OpenInNewIcon />}
                component={MuiLink}
            >
                Go to the Fee Waiver (interactive form)
            </QuaternaryButton>,
            <Typography variant="body2">
                Leave this website to get started on your Fee Waiver to ask the court to let you file an Answer without paying the fee.
            </Typography>,
            <QuaternaryButton
                target="_blank"
                download="21-04-21-how-to-ask-the-court-to-waive-fees-mlsa-form-packet.pdf"
                href={`${process.env.PUBLIC_URL}/files/21-04-21-how-to-ask-the-court-to-waive-fees-mlsa-form-packet.pdf`}
                icon={<DownloadIcon />}
                component={MuiLink}
            >
                Download the Fee Waiver (PDF)
            </QuaternaryButton>,
            <Typography variant="body2">
                Download the write-in-the-blank Fee Waiver and complete by hand to ask the court to let you file an Answer without paying the fee.
            </Typography>,
        ],
        hideReasons: true,
        order: 1,
    },
    protectedRequest: {
        title: 'Legal forms for Protected Income & Property',
        icon: <InsertDriveFileIcon />,
        actions: [
            <QuaternaryButton
                download
                href={`${process.env.PUBLIC_URL}/files/Homestead_Declaration_Instructions_Rev.2021.pdf`}
                icon={<DownloadIcon />}
                component={MuiLink}
            >
                Download Homestead Declaration
            </QuaternaryButton>,
            <Typography variant="body2">
                You must file a Homestead Declaration to protect your home.
            </Typography>,
            <QuaternaryButton
                download
                href={`${process.env.PUBLIC_URL}/files/How to Claim Exemptions and Request a Hearing Final.pdf`}
                icon={<DownloadIcon />}
                component={MuiLink}
            >
                Download Notice of Claimed Exemptions
            </QuaternaryButton>,
            <QuaternaryButton
                download
                target="_blank"
                rel="noReferrer"
                href={`${process.env.PUBLIC_URL}/files/Protected Income and Property Final.pdf`}
                icon={<DownloadIcon />}
                component={MuiLink}
            >
                Download Protected Income and Property Worksheet
            </QuaternaryButton>,
            <Typography variant="body2">
                If your protected income or property  is taken, you have only <strong>10 business days after you get a notice</strong> that money was taken to file this form.
            </Typography>,
        ],
        hideReasons: true,
        order: 5,
    },
    viewProtectedProperty: {
        title: 'Your income and property that is protected',
        icon: <LockIcon />,
        useLabels: true,
        reasonsListLabel: 'Based on your answers:',
        order: 2,
    },
    viewUnprotectedProperty: {
        title: 'Your income and property that is not protected',
        icon: <LockOpenIcon />,
        useLabels: true,
        reasonsListLabel: 'Based on your answers:',
        order: 3,
    },
    contactLawyer: {
        title: 'Apply for free legal help',
        icon: <PhoneEnabledIcon />,
        actions: [
            <Typography variant="body2">
                If you still have questions about protecting your income or property, it’s a good idea to talk to a lawyer. You can apply for free legal help or hire a private lawyer.
            </Typography>,
            <QuaternaryButton
                target="_blank"
                href="https://mtoi.legalserver.org/modules/matter/extern_intake.php?pid=69&h=1e34a2"
                icon={<OpenInNewIcon />}
                component={MuiLink}
            >
                Apply online
            </QuaternaryButton>,
            <QuaternaryButton
                href="tel:+18006666899"
                icon={<PhoneEnabledIcon />}
                component={MuiLink}
            >
                Call our Helpline 1-800-666-6899
            </QuaternaryButton>,
        ],
        hideReasons: true,
        order: 6,
    },
    consumerProtectionAct: {
        title: 'Read tips for dealing with a creditor',
        icon: <OpenInNewIcon />,
        actions: [
            <QuaternaryButton
                to="/creditorTips"
                icon={<OpenInNewIcon />}
                component={Link}
            >
                Tips for Dealing with a Creditor
            </QuaternaryButton>,
        ],
        hideReasons: true,
        order: 3,
    },
    fileAnswerForm: {
        title: 'File a Written Answer Form',
        icon: <OpenInNewIcon />,
        actions: [
            <QuaternaryButton
                target="_blank"
                href="https://lawhelpinteractive.org/Interview/GenerateInterview/8005/engine"
                icon={<OpenInNewIcon />}
                component={MuiLink}
            >
                Answer to complaint form
            </QuaternaryButton>,
        ],
        order: 4,
    },
    debtHarassmentInformation: {
        title: 'Find out if the Debt Collector Broke the Law',
        icon: <InfoIcon />,
        reasonsListLabel: 'Based on your answers:',
        order: 1,
    },
    debtHarassmentLegalForms: {
        title: 'Legal forms for Debt Collector Harassment',
        icon: <DownloadIcon />,
        actions: [
            <QuaternaryButton
                download
                href={`${process.env.PUBLIC_URL}/files/Debt Validation Packet Rev. 19-06-21.pdf`}
                icon={<DownloadIcon />}
                component={MuiLink}
            >
                Download Debt Validation Letter
            </QuaternaryButton>,
            <Typography variant="body2">
                Use this letter to tell a debt collector that they have not given you proper written notice about the debt.
            </Typography>,
            <QuaternaryButton
                download
                href={`${process.env.PUBLIC_URL}/files/Debt_Dispute_Letter_Packet_Rev_2022.pdf`}
                icon={<DownloadIcon />}
                component={MuiLink}
            >
                Download Debt Dispute Letter
            </QuaternaryButton>,
            <Typography variant="body2">
                Use this letter to dispute a debt that you don’t think you owe if the debt collector hasn’t already filed a lawsuit against you.
            </Typography>,
            <QuaternaryButton
                download
                href={`${process.env.PUBLIC_URL}/files/Cease_Contact_Letter_Debtor_Packet_Rev_2022.pdf`}
                icon={<DownloadIcon />}
                component={MuiLink}
            >
                Download Cease Contact Letter
            </QuaternaryButton>,
            <Typography variant="body2">
                This won&apos;t prevent a debt collector from suing you but it can stop them from continuing to contact you.
            </Typography>,
            <QuaternaryButton
                target="_blank"
                href={`${process.env.PUBLIC_URL}/files/debt-collector-phone-call-checklist-rev.-19-04-20.pdf`}
                download
                icon={<DownloadIcon />}
                component={MuiLink}
            >
                Download Phone Contact Log
            </QuaternaryButton>,
            <Typography variant="body2">
                Keep a record of times when you think a debt collector broke the law.
            </Typography>,
        ],
        order: 2,
        hideReasons: true,
    },
    contactLawyerHarassments: {
        title: 'Apply for free legal help',
        icon: <PhoneEnabledIcon />,
        actions: [
            <Typography variant="body2">
                If you have questions about your options or think the debt collector broke the law, you should talk to a lawyer right away. You can hire a private lawyer or apply for free legal help.
            </Typography>,
            <QuaternaryButton
                target="_blank"
                href="https://mtoi.legalserver.org/modules/matter/extern_intake.php?pid=69&h=1e34a2"
                icon={<OpenInNewIcon />}
                component={MuiLink}
            >
                Apply online
            </QuaternaryButton>,
            <QuaternaryButton
                href="tel:+18006666899"
                icon={<PhoneEnabledIcon />}
                component={MuiLink}
            >
                Call our Helpline 1-800-666-6899
            </QuaternaryButton>,
        ],
        hideReasons: true,
        order: 6,
    },
    contactLawyerLawsuits: {
        title: 'Apply for free legal help',
        icon: <PhoneEnabledIcon />,
        actions: [
            <Typography variant="body2">
                If you have questions about filing an Answer, it’s a good idea to talk to a lawyer. You can apply for free legal help or hire a private lawyer.
            </Typography>,
            <QuaternaryButton
                target="_blank"
                href="https://mtoi.legalserver.org/modules/matter/extern_intake.php?pid=69&h=1e34a2"
                icon={<OpenInNewIcon />}
                component={MuiLink}
            >
                Apply online
            </QuaternaryButton>,
            <QuaternaryButton
                href="tel:+18006666899"
                icon={<PhoneEnabledIcon />}
                component={MuiLink}
            >
                Call our Helpline 1-800-666-6899
            </QuaternaryButton>,
        ],
        hideReasons: true,
        order: 6,
    },
};
