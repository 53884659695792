import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './styles';
import Link from '../link';
import MainMenu from './main-menu';
import { getHasResults } from '../../selectors/answers';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const location = useLocation();
    const hasResults = useSelector(getHasResults);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    return (
        <>
            <AppBar position="static" sx={styles.root}>
                <Container>
                    <Toolbar disableGutters sx={styles.navLinks}>
                        <Link to="/" sx={styles.navTitle}>
                            Montana Debt Options Tool
                        </Link>
                        { isDesktop ? (
                            <Box>
                                <Link
                                    to="/"
                                    className={location.pathname === '/landing' ? 'activeLink' : ''}
                                    sx={styles.navLink}
                                >
                                    Home
                                </Link>
                                <Link
                                    to="/calculator"
                                    className={location.pathname === '/calculator' ? 'activeLink' : ''}
                                    sx={styles.navLink}
                                >
                                    Garnishment Calculator
                                </Link>
                                { hasResults && (
                                    <Link
                                        to="/results"
                                        className={location.pathname === '/results' ? 'activeLink' : ''}
                                        sx={styles.navLink}
                                    >
                                        Your Results
                                    </Link>
                                )}
                                <Link
                                    to="/resources"
                                    className={location.pathname === '/resources' ? 'activeLink' : ''}
                                    sx={styles.navLink}
                                >
                                    All Resources
                                </Link>
                            </Box>
                        ) : (
                            <IconButton
                                color="inherit"
                                aria-label="open menu"
                                onClick={toggleMenu}
                                sx={styles.menuIcon}
                            >
                                <MenuIcon fontSize="large" />
                            </IconButton>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
            <MainMenu
                open={menuOpen}
                onClose={toggleMenu}
                showResults={hasResults}
            />
        </>
    );
};

export default Header;
