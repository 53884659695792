import Colors from '../../styles/colors';

export default ({ theme }) => ({
    '& .MuiRadio-root': {
        color: 'inherit',
    },
    '& .MuiRadio-root.Mui-checked': {
        color: theme.palette.quaternary.contrastText,
    },
    '& .MuiFormControlLabel-root.Radio-label': {
        border: `1px solid ${Colors.offWhite}`,
        padding: '16px 12px',
        borderRadius: '2px',
        marginBottom: '15px',
    },
    '& .MuiFormControlLabel-root .radioLabel': {
        fontSize: '1.6rem',
        fontWeight: 'bold',
    },
    '& .MuiFormControlLabel-root.Radio-label.Radio-label-checked': {
        border: `1px solid ${theme.palette.quaternary.contrastText}`,
    },
});
