import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';
import styles from './styles';

const MenuLink = ({ children, ...props }) => (
    <Link
        underline="none"
        component={NavLink}
        sx={styles}
        {...props}
    >
        { children }
    </Link>
);

MenuLink.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.func, PropTypes.string]),
};

MenuLink.defaultProps = {
    children: null,
};

export default MenuLink;
