import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import MenuLink from '../menu-link';
import noop from '../../utils/noop';

const MenuItemList = styled(List)({ fontFamily: '"Roboto", sans-serif' });

const MainMenu = ({ open, onClose, showResults }) => (
    <Drawer
        anchor="right"
        onClose={onClose}
        open={open}
    >
        <Container sx={{ minWidth: '300px' }}>
            <Typography align="right" pt={1.5}>
                <IconButton
                    color="primary"
                    aria-label="close menu"
                    onClick={onClose}
                >
                    <MenuIcon fontSize="large" />
                </IconButton>
            </Typography>
            <MenuItemList>
                <ListItem>
                    <MenuLink to="/" onClick={onClose}>Home</MenuLink>
                </ListItem>
                <Divider />
                <ListItem>
                    <MenuLink to="/calculator" onClick={onClose}>Garnishment Calculator</MenuLink>
                </ListItem>
                <Divider />
                { showResults && (
                    <>
                        <ListItem>
                            <MenuLink to="/results" onClick={onClose}>Your Results</MenuLink>
                        </ListItem>
                        <Divider />
                    </>
                )}
                <ListItem>
                    <MenuLink to="/resources" onClick={onClose}>All Resources</MenuLink>
                </ListItem>
                <Divider />
            </MenuItemList>
        </Container>
    </Drawer>
);

MainMenu.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    showResults: PropTypes.bool,
};

MainMenu.defaultProps = {
    open: false,
    onClose: noop,
    showResults: false,
};

export default MainMenu;
