import ResultsActions from '../action-types/results';

const initial = {
    exempt: [],
    garnishment: {},
    legalDefenses: [],
    numberOfVisits: 0,
};

// eslint-disable-next-line default-param-last
const ResultsReducer = (state = initial, action) => {
    const { payload, type } = action;

    switch (type) {
    case ResultsActions.UPDATE_RESULTS:
        return { ...state, [payload.flowName]: payload.results };
    case ResultsActions.RESET_RESULTS:
        return { ...state, [payload.flowName]: initial[payload.flowName] };
    case ResultsActions.INCREMENT_VISITS:
        return { ...state, numberOfVisits: state.numberOfVisits ? state.numberOfVisits + 1 : 1 };
    default:
        return state;
    }
};

export default ResultsReducer;
