import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';

import Styles from './styles';

const ConditionalQuestions = ({
    condition,
    children,
    hideBorder,
    hideContainer,
}) => (
    <Collapse in={condition}>
        <Box sx={Styles(hideContainer, hideBorder)}>
            {children}
        </Box>
    </Collapse>
);

ConditionalQuestions.defaultProps = {
    hideBorder: false,
    hideContainer: false,
};
ConditionalQuestions.propTypes = {
    condition: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    hideBorder: PropTypes.bool,
    hideContainer: PropTypes.bool,
};

export default ConditionalQuestions;
