/* eslint-disable import/prefer-default-export */
import AnswersTypes from '../action-types/answers';
import answerResultsMap from '../constants/answerResultsMap';
import flows from '../constants/flows';
import { restartFlow } from './flow';
import { calculateResults } from './results';

export const updateAnswers = (flowName, values) => async (dispatch) => {
    await dispatch({
        type: AnswersTypes.UPDATE_ANSWERS,
        payload: {
            flowName,
            values,
        },
    });
    if (answerResultsMap[flowName]) {
        return dispatch(calculateResults(flowName));
    }
    return Promise.resolve();
};

export const resetAnswers = (flowName) => (dispatch) => {
    dispatch(restartFlow(flowName));
    return dispatch({
        type: AnswersTypes.RESET_ANSWERS,
        payload: { flowName },
    });
};

export const resetAllAnswers = () => (dispatch) => {
    const dispatches = flows.map((flowName) => dispatch(resetAnswers(flowName)));
    return Promise.all(dispatches);
};
