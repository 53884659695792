export default {
    root: {
        backgroundColor: 'primary.light',
    },
    navTitle: {
        color: 'primary.contrastText',
        flex: 1,
        fontWeight: 500,
    },
    navLink: {
        color: 'secondary.light',
        marginLeft: '30px',
        '&.activeLink': {
            color: 'primary.contrastText',
            textDecoration: 'underline',
        },
        ':hover': {
            color: 'primary.contrastText',
        },
    },
    navLinks: {
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 'bold',
    },
    menu: {
        minWidth: '320px',
    },
    menuIcon: {
        position: 'absolute',
        right: '10px',
    },
};
