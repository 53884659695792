import { createTheme } from '@mui/material/styles';
import Colors from './colors';
// https://material-ui.com/customization/default-theme/?expend-path=$.palette.background
// https://fonts.adobe.com/fonts/ibm-plex-sans#fonts-section
const theme = createTheme({
    breakpoints: { // using bootstrap defined breakpoints for consistancy
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '1.2rem',
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                expandIconWrapper: {
                    '& .MuiSvgIcon-root': {
                        width: '2rem',
                        height: '2rem',
                    },
                },
            },
        },
        MuiFormControlLabel: { // checkboxes, radio buttons
            styleOverrides: {
                root: {
                    '& .MuiFormControlLabel-label': {
                        width: '100%',
                    },
                    '&.Checkbox-label': {
                        color: Colors.greyDarker,
                        border: `1px solid ${Colors.offWhite}`,
                        padding: 0,
                        margin: 0,
                        marginBottom: '12px',
                        minHeight: '47px',
                    },

                    '&.Checkbox-label-checked': {
                        color: Colors.greyDark,
                    },

                    '&.Radio-label': {
                        alignItems: 'flex-start',
                        margin: 0,
                        '& .MuiIconButton-root': {
                            ':hover': {
                                backgroundColor: 'inherit',
                            },
                        },
                    },
                    '& .radioLabel': {
                        flex: 1,
                        fontFamily: '"Roboto", sans-serif',
                        color: Colors.greyDarker,
                        fontSize: '1.6rem',
                        fontWeight: 'normal',
                    },
                    '& .radioDescription': {
                        fontWeight: 'normal',
                        fontFamily: '"Roboto", sans-serif',
                        fontSize: '1.6rem',
                    },
                },
                label: {
                    color: Colors.greyDarker,
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    lineHeight: '25px',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: Colors.greyDarker,
                    fontSize: '1.6rem',
                    position: 'relative',
                    fontFamily: '"Roboto", sans-serif',
                    marginBottom: '10px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: Colors.greyDarker,
                    fontSize: '1.6rem',
                    letterSpacing: '0.0625rem',
                    position: 'relative',
                    fontFamily: '"Roboto", sans-serif',
                    whiteSpace: 'normal',
                },
                shrink: {
                    transform: 'translate(0, 1.5px) scale(1)',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#999999',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                    lineHeight: '1.4rem',
                    letterSpacing: '0.1rem',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.white,
                    borderColor: Colors.offWhite,
                    color: Colors.greyDarker,
                    fontFamily: '"Roboto", sans-serif',
                    fontSize: '1.6rem',
                    width: '105px',
                    borderRadius: 1,
                    marginTop: 5,
                },
                input: {
                    padding: '10px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    paddingTop: 'inherit',
                    paddingBottom: 'inherit',
                    boxShadow: 'none',
                },
                elevation0: { // select menus
                    border: `2px solid ${Colors.greyLight}`,
                    borderRadius: 5,
                    paddingTop: 3,
                    paddingBottom: 3,
                },
                elevation1: { // select menus
                    border: `1px solid ${Colors.offWhite}`,
                    borderRadius: 0,
                    paddingTop: 3,
                    paddingBottom: 3,
                },
                elevation2: { // regular menus
                    borderRadius: 5,
                },
                elevation24: { // dialogs
                    borderRadius: 5,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: Colors.greyDarker,
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    lineHeight: 1.1875,

                    '&:hover': {
                        color: Colors.blue,
                        backgroundColor: `rgba(${Colors.blueRGB}, 0.1)`,
                    },

                    '&[aria-selected=true]': {
                        color: Colors.white,
                        backgroundColor: Colors.blue,
                    },

                    '&[role=option]': { // select menu options
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 22,
                        paddingRight: 22,
                    },

                    '&[role=menu]': { // menu items

                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '100%',
                    '@media (min-width: 576px)': {
                        minWidth: '272px',
                        width: 'auto',
                    },
                    padding: '13px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                },
                contained: {
                    lineHeight: '1.6rem',
                    textTransform: 'uppercase',
                    fontSize: '1.6rem',
                    borderRadius: '2px',
                    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12)',
                    '&.MuiButton-containedSecondary': {
                        backgroundColor: Colors.offWhite,
                        color: Colors.greyDarker,
                    },
                },
                outlined: {
                    borderRadius: 2,
                    fontSize: '1.6rem',
                    lineHeight: 1,
                    textTransform: 'none',
                    minWidth: '70px',
                    width: 'auto',
                    '@media (min-width: 576px)': {
                        minWidth: '70px',
                        width: 'auto',
                    },
                },
                text: {
                    fontSize: '1.6rem',
                    lineHeight: 1,
                    letterSpacing: '0.05rem',
                    color: Colors.blue,

                },
                textSecondary: {
                    color: Colors.white,
                    textTransform: 'none',
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                primary: {
                    backgroundColor: Colors.blue,
                    color: Colors.white,
                    boxShadow: `-1px 2px 3px 0 rgba(${Colors.blueDarkRGB}, 0.3)`,

                    '&.Mui-disabled, &[aria-disabled=true]': {
                        opacity: '40%',
                        backgroundColor: `${Colors.blue} !important`,
                        color: `${Colors.white} !important`,
                    },
                },
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: Colors.greyDarker,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '2.4rem',
                    borderBottom: `1px solid ${Colors.offWhite}`,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    border: `1px solid ${Colors.greyLight}`,
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '20px 24px',
                    width: '100%',
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: '0 0 6px 0 rgba(0,0,0,0.1)',
                    '&.Mui-expanded': {
                        paddingBottom: 0,
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    borderTop: `1px solid ${Colors.offWhite}`,
                    backgroundColor: Colors.almostWhite,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: Colors.blue,
                    paddingTop: '5px',
                    paddingLeft: 0,

                    ':hover': {
                        backgroundColor: 'inherit',
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: Colors.blueMedium,
                },
            },
        },
    },
    typography: {
        useNextVariants: true,
        h1: {
            fontSize: '3.2rem',
            fontWeight: 300,
            fontStyle: 'normal',
            color: Colors.greyDarker,
        },
        h2: {
            fontSize: '2.4rem',
            fontWeight: 700,
            fontStyle: 'normal',
            color: Colors.blue,
        },
        h3: {
            fontSize: '1.8rem',
            fontWeight: 'bold',
            color: Colors.charcoal,
        },
        h4: {
            fontSize: '1.6rem',
            lineHeight: '24px',
            fontFamily: '"Roboto", sans-serif',
        },
        h5: {
            fontSize: '1.6rem',
            lineHeight: '24px',
            fontFamily: '"Roboto", sans-serif',
            color: Colors.white,
        },
        body1: {
            fontSize: '1.6rem',
            lineHeight: '24px',
            fontWeight: 400,
            fontFamily: '"Roboto", sans-serif',
            letterSpacing: 'normal',
        },
        body2: {
            fontSize: '1.6rem',
            lineHeight: '24px',
            fontWeight: 400,
            fontFamily: '"Roboto", sans-serif',
            letterSpacing: 'normal',
        },
        button: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
        },
    },
    palette: {
        text: {
            primary: Colors.greyDarker,
            secondary: Colors.grey,
        },
        primary: {
            light: Colors.blue,
            main: Colors.blueDark,
            contrastText: Colors.white,
        },
        secondary: {
            main: Colors.greyDarker,
            light: Colors.greyLight,
            contrastText: Colors.greyDark,
        },
        tertiary: {
            main: Colors.aquaLight,
            dark: Colors.aqua,
            light: Colors.aquaHighlight,
            contrastText: Colors.black,
        },
        quaternary: {
            main: Colors.white,
            dark: Colors.offWhite,
            contrastText: Colors.blueMedium,
        },
        background: {
            default: Colors.whiteDark,
        },
        error: {
            main: Colors.error,
        },
        success: {
            main: Colors.success,
        },
    },
});

export default theme;
