import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import PrimaryButton from '../buttons/primary-button';
import styles from './styles';
import Logo from '../../images/mlsa_logo.png';

const FooterContainer = styled('footer')(styles.container);

const Footer = () => (
    <FooterContainer>
        <Container>
            <Grid container spacing={4}>
                <Grid item xs={6} md={4} lg={3} xl={2} mb={2}>
                    <img height="auto" width="100%" alt="MLSA Logo" src={Logo} style={{ maxWidth: '275px' }} />
                </Grid>
                <Grid item xs={12} sm={6} md={8} lg={9} xl={10}>
                    <Typography variant="h1" sx={styles.title}>
                        Apply for Free Legal Help
                    </Typography>
                    <Typography mt={1}>
                        Montana Legal Services Association may be able to help if you have questions about your rights or options. The best way to find out if we can help is to apply online or call our Helpline number.
                    </Typography>
                    <Typography mt={2} mb={2}>
                        <PhoneIcon />&nbsp;
                        1-800-666-6899
                    </Typography>
                    <PrimaryButton sx={styles.button} component={Link} href="https://mtoi.legalserver.org/modules/matter/extern_intake.php?pid=69&h=1e34a2" target="_blank">
                        apply online
                    </PrimaryButton>
                    <Typography mt={1} textDecoration="underline">
                        <Link href="mailto:feedback@mtlsa.org" style={{ textDecoration: 'underline' }}>
                            Send Feedback
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container mt={8}>
                <Grid item xs={6}>
                    <RouterLink to="/privacy">
                        Privacy Policy
                    </RouterLink>
                </Grid>
                <Grid item xs={6}>
                    <Typography align="right">{new Date().getFullYear()} MLSA All rights reserved</Typography>
                </Grid>
            </Grid>
        </Container>
    </FooterContainer>
);

export default Footer;
