import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ContentCard from '../../components/content-card';
import PrimaryButton from '../../components/buttons/primary-button';
import styles from './styles';
import SubheaderTypography from '../../components/subheader-typography';
import { getHasResults } from '../../selectors/answers';

const Landing = () => {
    const hasResults = useSelector(getHasResults);
    const getStartedText = hasResults ? 'Continue' : 'Get Started';
    return (
        <Container>
            <SubheaderTypography variant="h1" mt={6} mb={3}>
                Welcome!
            </SubheaderTypography>
            <SubheaderTypography mb={3}>
                Use the Montana Debt Options Tool to find out if a debt collector could be breaking the law, if you have any protected income or property, and if you have any defenses to a debt lawsuit.
            </SubheaderTypography>
            <ContentCard>
                <Typography variant="h1" sx={styles.cardTitle}>
                    GET STARTED
                </Typography>
                <Typography variant="h2">
                    Disclaimer
                </Typography>
                <Typography mt={1}>
                    Montana Legal Services Association (MLSA) created this tool to help you know your rights when dealing with a consumer debt. This tool gives you specific resources based on your answers to questions on the following screens. But, this tool can’t take the place of advice from a lawyer and doesn’t create a client-attorney relationship between you and MLSA.
                </Typography>
                <Typography mt={1}>
                    To get the most out of this tool, it’s a good idea to have the following:
                </Typography>
                <ul>
                    <li>Court papers you&apos;ve been served (if any)</li>
                    <li>Letters you got from a debt collector (if any)</li>
                    <li>Paystubs (if any)</li>
                </ul>
                <Typography>
                    This tool is not for any debt related to child support, student loans, taxes, small claims court, foreclosure, or repossession. If you need help with any of those matters, please visit <a href="http://montanalawhelp.org/" target="_blank" rel="noreferrer">MontanaLawHelp.org</a>.
                </Typography>
                <Typography mt={1} mb={3}>
                    If you have questions about your rights or options, it’s a good idea to talk to a lawyer.
                </Typography>
                <PrimaryButton component={Link} to="judgement">
                    {getStartedText}
                </PrimaryButton>
            </ContentCard>
        </Container>
    );
};

export default Landing;
