import React, { lazy } from 'react';
import {
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';

const ExemptIncomeGettingStarted = lazy(() => import('../../routes/exempt-income'));
const ExemptPropertiesQuestionnaire = lazy(() => import('../../routes/exempt-income/property'));
const ExemptIncomeQuestionnaire = lazy(() => import('../../routes/exempt-income/income'));

const ExemptLayout = () => (
    <Routes>
        <Route path="/" element={<Navigate replace to="start" />} />
        <Route path="/start" element={<ExemptIncomeGettingStarted />} />
        <Route path="/properties" element={<ExemptPropertiesQuestionnaire />} />
        <Route path="/income" element={<ExemptIncomeQuestionnaire />} />
    </Routes>
);

export default ExemptLayout;
