export const flowNames = {
    EXEMPT: 'exempt',
    LEGAL_DEFENSES: 'legalDefenses',
    HARASSMENTS: 'harassments',
    JUDGEMENT: 'judgement',
    GARNISHMENT: 'garnishment',
};

export default [
    flowNames.EXEMPT,
    flowNames.LEGAL_DEFENSES,
    flowNames.HARASSMENTS,
    flowNames.JUDGEMENT,
    flowNames.GARNISHMENT,
];
