import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';

import ConditionalQuestions from '../conditional-questions';

const RadioLabel = ({ label, description, onOpenTooltip }) => (
    <Box display="flex">
        <Box flex={1}>
            <strong className="radioLabel">
                {label}
            </strong>
            <Box className="radioDescription">
                {description}
            </Box>
        </Box>
        { onOpenTooltip && (
            <IconButton onClick={onOpenTooltip}>
                <HelpIcon />
            </IconButton>
        )}
    </Box>
);

RadioLabel.propTypes = {
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    onOpenTooltip: PropTypes.func,
};

RadioLabel.defaultProps = {
    description: '',
    onOpenTooltip: null,
};

const RadioButton = ({
    value,
    label,
    description,
    onOpenTooltip,
    required,
    disabled,
    isChecked,
    inputRef,
    inputProps,
    conditional,
}) => {
    const elementProps = {
        ...inputProps,
        'aria-required': required,
        required,
    };

    return (
        <>
            <FormControlLabel
                className={`Radio-label ${isChecked ? 'Radio-label-checked' : ''}`} // targeted in material-theme
                value={value}
                label={<RadioLabel description={description} onOpenTooltip={onOpenTooltip} label={label} />}
                checked={isChecked}
                inputRef={inputRef}
                disabled={disabled}
                control={(
                    <Radio
                        color="default"
                        inputProps={elementProps}
                    />
                )}
            />

            { conditional && (
                <ConditionalQuestions hideBorder condition={isChecked}>
                    {conditional}
                </ConditionalQuestions>
            )}
        </>
    );
};

RadioButton.defaultProps = {
    required: false,
    disabled: false,
    isChecked: false,
    inputRef: null,
    inputProps: null,
    conditional: null,
    description: '',
    onOpenTooltip: null,
};

RadioButton.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    isChecked: PropTypes.bool,
    inputRef: PropTypes.func,
    inputProps: PropTypes.object,
    conditional: PropTypes.node,
    description: PropTypes.string,
    onOpenTooltip: PropTypes.func,
};

export default RadioButton;
