import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Footer from '../../components/footer';
import Header from '../../components/header';
import Landing from '../../routes/landing';
import theme from '../../styles/material-theme';
import withRoot from '../../wiring/with-root';
import backgroundImage from './images/background-image-with-overlay.png';
import DeclareSituationForm from '../../routes/declare-situation';

const Main = styled('main')({
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 437px',
});

const LandingLayout = () => (
    <Main>
        <Header />
        <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/judgement" element={<DeclareSituationForm />} />
        </Routes>
        <Footer />
    </Main>
);

export default withRoot(LandingLayout, theme);
