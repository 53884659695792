export default {
    white: '#ffffff',
    almostWhite: '#fafafa',
    whiteRGB: '255, 255, 255',
    whiteDark: '#F5F5F5',
    offWhite: '#EEEEEE',
    black: '#2F3135',
    blackRGB: '47,49,53',
    charcoal: '#333333',
    grey: '#6C757D',
    greyRGB: '195, 196, 196',
    greyDark: '#999999',
    greyDarker: '#505153',
    greyLight: '#E9EAEB',
    greyLightRGB: '233,234,235',
    regalBlue: '#1D2E4B',
    regalBlueRGB: '29, 46, 75',
    blue: '#0074BC',
    blueHighlight: '#5A86EB',
    blueDark: '#0B4E9C',
    blueDarkRGB: '12, 15, 54',
    blueLight: '#E5F1F8',
    blueMedium: '#0E66CC',
    yellowGreen: '#D3E67E',
    aqua: '#63C4C0',
    aquaLight: '#6AD2CD',
    aquaHighlight: '#78E3DC',
    error: '#C24444',
    success: '#13AE47',
    green: '#229C4A',
};
