import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import noop from '../../../utils/noop';
import styles from './styles';

const TertiaryStyledButton = styled(Button)(styles);

const TertiaryButton = ({ children, onClick, ...props }) => (
    <TertiaryStyledButton
        variant="contained"
        onClick={onClick}
        {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
        { children }
    </TertiaryStyledButton>
);

TertiaryButton.defaultProps = {
    children: null,
    onClick: noop,
};

TertiaryButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
};

export default TertiaryButton;
