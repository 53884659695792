import FlowActions from '../action-types/flow';

export const completeFlow = (flowName) => ({
    type: FlowActions.COMPLETE_FLOW,
    payload: flowName,
});

export const restartFlow = (flowName) => ({
    type: FlowActions.RESTART_FLOW,
    payload: flowName,
});
