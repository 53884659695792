import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';

const Link = (props) => (
    <MuiLink
        component={RouterLink}
        underline="none"
        color="primary.light"
        href="#"
        {...props}
    />
);

export default Link;
