import Colors from '../../../styles/colors';

const styles = (hideContainer, noBorder) => ({
    paddingLeft: hideContainer ? 'inherit' : 30,
    paddingBottom: hideContainer ? 'inherit' : 20,
    borderBottom: hideContainer ? 'inherit' : `1px solid ${Colors.clouds}`,
    border: noBorder ? 0 : 'inherit',
});

export default styles;
