import ResultsActions from '../action-types/results';
import answerResultsMap from '../constants/answerResultsMap';
import { getAnswersForFlow } from '../selectors/answers';
import allResults from '../constants/results';
import flows from '../constants/flows';

export const updateResults = (flowName, results) => ({
    type: ResultsActions.UPDATE_RESULTS,
    payload: {
        flowName,
        results,
    },
});

export const calculateResults = (flowName) => async (dispatch, getState) => {
    const state = getState();
    const answers = getAnswersForFlow(state)(flowName);
    const results = {};

    // get all unique results paired up with their corresponding answers
    const constructResult = (answer) => {
        const resultKeys = answerResultsMap[flowName][answer];

        if (!resultKeys) {
            return;
        }

        resultKeys.forEach((resultKey) => {
            if (!results[resultKey]) {
                results[resultKey] = {
                    reasons: [],
                };
            }
            results[resultKey].reasons.push(answer);
        });
    };

    Object.values(answers).forEach((answer) => {
        // checkbox answers will have multiple values
        if (Array.isArray(answer)) {
            answer.forEach((value) => constructResult(value));
        } else {
            constructResult(answer);
        }
    });

    // turn the results into a list
    const resultsList = Object.entries(results).map(([key, value]) => ({
        ...value,
        ...allResults[key],
        key,
    }));
    return dispatch(updateResults(flowName, resultsList));
};

export const resetResults = (flowName) => ({
    type: ResultsActions.RESET_RESULTS,
    payload: { flowName },
});

export const resetAllResults = () => (dispatch) => {
    const dispatches = flows.map((flowName) => dispatch(resetResults(flowName)));
    return Promise.all(dispatches);
};

export const incrementVisits = () => ({
    type: ResultsActions.INCREMENT_VISITS,
});
