import Colors from '../../styles/colors';

export default {
    container: {
        backgroundColor: Colors.charcoal,
        color: Colors.white,
        padding: '32px 0',
        '& a': {
            color: Colors.white,
            textDecoration: 'none',
        },
    },
    title: {
        fontSize: '1.8rem',
        fontWeight: 'bold',
        color: Colors.white,
    },
    button: {
        backgroundColor: 'primary.light',
        minWidth: {
            sm: 'auto',
        },
        width: 'auto',
    },
};
